module.exports={
    "Unknown": { "abbreviation": "", "clueColors": [], "fill": "#cccccc" },

    "Blue": { "pip": "diamond" },
    "Green": { "pip": "club" },
    "Yellow": { "pip": "star" },
    "Red": { "pip": "heart" },
    "Purple": { "pip": "crescent" },

    "Teal": { "pip": "flower" },
    "Black": { "oneOfEach": true, "pip": "spade" },
    "Rainbow": {
        "abbreviation": "M",
        "allClueColors": true,
        "fill": "multi",
        "fillColors": ["#0044cc", "#00cc00", "#ccaa22", "#aa0000", "#6600cc"],
        "pip": "rainbow"
    },
    "Pink": { "clueRanks": "all", "pip": "number_sign" },
    "White": { "clueColors": [], "fill": "#d9d9d9", "pip": "circle" },
    "Brown": { "clueRanks": "none", "pip": "tensor_symbol" },
    "Omni": {
        "allClueColors": true,
        "clueRanks": "all",
        "fill": "multi",
        "fillColors": ["#000000", "#0044cc", "#00cc00", "#ccaa22", "#aa0000", "#6600cc", "#000000"],
        "pip": "infinity"
    },
    "Null": { "clueColors": [], "clueRanks": "none", "fill": "#ffffff", "pip": "null_symbol" },

    "Dark Rainbow": {
        "abbreviation": "M",
        "allClueColors": true,
        "fill": "multi",
        "fillColors": ["#000555", "#005505", "#555500", "#5f0000", "#550055"],
        "oneOfEach": true,
        "pip": "rainbow"
    },
    "Dark Pink": {
        "abbreviation": "I",
        "clueColors": ["Pink"],
        "clueRanks": "all",
        "fill": "#800040",
        "oneOfEach": true,
        "pip": "infinity"
    },
    "Gray": {
        "abbreviation": "A",
        "clueColors": [],
        "fill": "#555555",
        "oneOfEach": true,
        "pip": "circle"
    },
    "Chocolate": {
        "clueColors": ["Brown"],
        "clueRanks": "none",
        "fill": "#271a0c",
        "oneOfEach": true,
        "pip": "null_symbol"
    },
    "Dark Omni": {
        "abbreviation": "O",
        "allClueColors": true,
        "clueRanks": "all",
        "fill": "multi",
        "fillColors": ["#000000", "#001a4d", "#004d00", "#41370b", "#4d0000", "#26004d", "#000000"],
        "oneOfEach": true,
        "pip": "infinity"
    },
    "Dark Null": {
        "abbreviation": "N",
        "clueColors": [],
        "clueRanks": "none",
        "fill": "#a6a6a6",
        "oneOfEach": true,
        "pip": "null_symbol"
    },

    "Muddy Rainbow": {
        "abbreviation": "M",
        "allClueColors": true,
        "clueRanks": "none",
        "fill": "multi",
        "fillColors": ["#654321", "#00cc00", "#ccaa22", "#aa0000", "#654321"],
        "pip": "rainbow"
    },
    "Light Pink": {
        "abbreviation": "I",
        "clueColors": [],
        "clueRanks": "all",
        "fill": "#ffcce6",
        "pip": "infinity"
     },

    "Blue CB": { "clueColors": [], "name": "Blue", "pip": "diamond" },
    "Green CB": { "clueColors": [], "name": "Green", "pip": "club" },
    "Yellow CB": { "clueColors": [], "name": "Yellow", "pip": "star" },
    "Red CB": { "clueColors": [], "name": "Red", "pip": "heart" },
    "Purple CB": { "clueColors": [], "name": "Purple", "pip": "crescent" },
    "Teal CB": { "clueColors": [], "name": "Teal", "pip": "flower" },

    "Blue NB": { "clueColors": ["Blue"], "clueRanks": "none", "name": "Blue", "pip": "diamond" },
    "Green NB": { "clueColors": ["Green"], "clueRanks": "none", "name": "Green", "pip": "club" },
    "Yellow NB": { "clueColors": ["Yellow"], "clueRanks": "none", "name": "Yellow", "pip": "star" },
    "Red NB": { "clueColors": ["Red"], "clueRanks": "none", "name": "Red", "pip": "heart" },
    "Purple NB": { "clueColors": ["Purple"], "clueRanks": "none", "name": "Purple", "pip": "crescent" },
    "Teal NB": { "clueColors": ["Teal"], "clueRanks": "none", "name": "Teal", "pip": "flower" },

    "Blue TB": { "clueColors": [], "clueRanks": "none", "name": "Blue", "pip": "diamond" },
    "Green TB": { "clueColors": [], "clueRanks": "none", "name": "Green", "pip": "club" },
    "Yellow TB": { "clueColors": [], "clueRanks": "none", "name": "Yellow", "pip": "star" },
    "Red TB": { "clueColors": [], "clueRanks": "none", "name": "Red", "pip": "heart" },
    "Purple TB": { "clueColors": [], "clueRanks": "none", "name": "Purple", "pip": "crescent" },
    "Teal TB": { "clueColors": [], "clueRanks": "none", "name": "Teal", "pip": "flower" },

    "Sky": { "clueColors": ["Blue"], "fill": "#1a66ff", "pip": "diamond" },
    "Navy": { "clueColors": ["Blue"], "fill": "#002b80", "pip": "club" },
    "Lime": { "clueColors": ["Green"], "fill": "#1aff1a", "pip": "star" },
    "Forest": { "clueColors": ["Green"], "fill": "#008000", "pip": "heart" },
    "Tomato": { "clueColors": ["Red"], "fill": "#e60000", "pip": "crescent" },
    "Mahogany": { "clueColors": ["Red"], "fill": "#660000", "pip": "flower" },

    "Sky VA": { "clueColors": ["Blue"], "fill": "#4d88ff", "name": "Sky", "pip": "diamond" },
    "Berry VA": { "clueColors": ["Blue"], "name": "Berry", "pip": "club" },
    "Navy VA": { "clueColors": ["Blue"], "fill": "#001a4d", "name": "Navy", "pip": "star" },
    "Tomato VA": { "clueColors": ["Red"], "fill": "#ff1a1a", "name": "Tomato", "pip": "heart" },
    "Ruby VA": { "clueColors": ["Red"], "name": "Ruby", "pip": "crescent" },
    "Mahogany VA": { "clueColors": ["Red"], "fill": "#330000", "name": "Mahogany", "pip": "flower" },

    "Green D": { "clueColors": ["Blue", "Yellow"], "name": "Green", "pip": "diamond" },
    "Purple D": { "clueColors": ["Blue", "Red"], "name": "Purple", "pip": "club" },
    "Navy D": { "clueColors": ["Blue", "Black"], "fill": "#000066", "name": "Navy", "pip": "star" },
    "Orange D": { "clueColors": ["Yellow", "Red"], "fill": "#ff8800", "name": "Orange", "pip": "heart" },
    "Tan D": { "clueColors": ["Yellow", "Black"], "fill": "#999900", "name": "Tan", "pip": "crescent" },
    "Mahogany D": { "clueColors": ["Red", "Black"], "fill": "#660016", "name": "Mahogany", "pip": "flower" },

    "Teal D": { "clueColors": ["Blue", "Green"], "name": "Teal", "pip": "diamond" },
    "Lime D": { "clueColors": ["Green", "Yellow"], "fill": "#80c000", "name": "Lime", "pip": "club" },
    "Orange D2": { "clueColors": ["Yellow", "Red"], "fill": "#ff8800", "name": "Orange", "pip": "star" },
    "Cardinal D": { "clueColors": ["Red", "Purple"], "fill": "#810735", "name": "Cardinal", "pip": "heart" },
    "Indigo D": { "clueColors": ["Purple", "Blue"], "fill": "#1a0082", "name": "Indigo", "pip": "crescent" },

    "Lime AD": { "clueColors": ["Blue", "Yellow"], "fill": "#1aff1a", "name": "Lime", "pip": "diamond" },
    "Forest AD": { "clueColors": ["Blue", "Yellow"], "fill": "#008000", "name": "Forest", "pip": "club" },
    "Orchid AD": { "clueColors": ["Blue", "Red"], "fill": "#8c1aff", "name": "Orchid", "pip": "star" },
    "Violet AD": { "clueColors": ["Blue", "Red"], "fill": "#400080", "name": "Violet", "pip": "heart" },
    "Tangelo AD": { "clueColors": ["Yellow", "Red"], "fill": "#ffac4d", "name": "Tangelo", "pip": "crescent" },
    "Peach AD": { "clueColors": ["Yellow", "Red"], "fill": "#b35f00", "name": "Peach", "pip": "flower" }
}
