/*
    In-game chat
*/

// Imports
const globals = require('../globals');

exports.init = () => {
    // Detect if an element is off screen
    // From: https://stackoverflow.com/questions/8897289/how-to-check-if-an-element-is-off-screen
    jQuery.expr.filters.offscreen = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top < 0 // Above the top
            || rect.bottom > window.innerHeight // Below the bottom
            || rect.left < 0 // Left of the left edge
            || rect.right > window.innerWidth // Right of the right edge
        );
    };

    // Make the chat modal draggable (using the InteractJS library)
    interact('.draggable')
        .draggable({
            allowFrom: '#game-chat-modal-header',

            // Keep the modal within the bounds of the page
            // (the parent is the <body> element)
            modifiers: [
                interact.modifiers.restrictRect({
                    restriction: 'parent',
                }),
            ],

            // Define the drag behavior
            onmove: (event) => {
                // Get the new position based on the delta between the event and the old position
                // (which is conveniently stored in the "data-x" and "data-y" attributes)
                const x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx;
                const y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy;

                // Move it
                const element = $(`#${event.target.id}`);
                moveElement(element, x, y);
            },
        })

        .resizable({
            // resize from all edges and corners
            edges: {
                // We don't want it to be resizable from the top since
                // it interferes with the area dedicated to moving the div
                left: true,
                right: true,
                bottom: true,
            },

            ignoreFrom: '#game-chat-modal-header',

            modifiers: [
                // Keep the modal within the bounds of the page
                // (the parent is the <body> element)
                interact.modifiers.restrictEdges({
                    outer: 'parent',
                }),

                // Define a minimum size for the modal
                interact.modifiers.restrictSize({
                    min: {
                        width: 200,
                        height: 200,
                    },
                }),
            ],
        })

        .on('resizemove', (event) => {
            // Get the new position based on the delta between the event and the old position
            // (which is conveniently stored in the "data-x" and "data-y" attributes)
            let x = (parseFloat(event.target.getAttribute('data-x')) || 0);
            let y = (parseFloat(event.target.getAttribute('data-y')) || 0);

            // Translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;

            // Move it
            const element = $(`#${event.target.id}`);
            moveElement(element, x, y);

            // Resize it
            event.target.style.width = `${event.rect.width}px`;
            event.target.style.height = `${event.rect.height}px`;
        })

        .on('dragend resizeend', (event) => {
            // The modal was moved or resized;
            // store the window dimensions in a cookie so that it will persist between refreshes
            localStorage.setItem('chatWindowWidth', event.target.style.width);
            localStorage.setItem('chatWindowHeight', event.target.style.height);
            localStorage.setItem('chatWindowX', $(`#${event.target.id}`).attr('data-x'));
            localStorage.setItem('chatWindowY', $(`#${event.target.id}`).attr('data-y'));
        });

    $('#game-chat-modal-header-close').click(() => {
        hide();
    });
};

exports.toggle = () => {
    const modal = $('#game-chat-modal');
    if (modal.is(':visible')) {
        hide();
    } else {
        show();
    }
};

const show = () => {
    const modal = $('#game-chat-modal');
    modal.fadeIn(globals.fadeTime);

    // Check to see if there are any uncurrently unread chat messages
    if (globals.chatUnread !== 0) {
        // If the user is opening the chat, then we assume that all of the chat messages are read
        globals.chatUnread = 0;
        globals.conn.send('chatRead'); // We need to notify the server that we have read everything
        globals.ui.updateChatLabel(); // Reset the "Chat" UI button back to normal
    }

    // Set the modal to the default position
    modal.css('width', '20%');
    modal.css('height', '50%');
    modal.css('top', '1%');
    modal.css('left', '79%');

    // If there is a stored size + position for the chat box, set that
    let resetPosition = true;
    const width = localStorage.getItem('chatWindowWidth');
    const height = localStorage.getItem('chatWindowHeight');
    const x = localStorage.getItem('chatWindowX');
    const y = localStorage.getItem('chatWindowY');
    if (
        width !== null && width !== ''
        && height !== null && height !== ''
        && x !== null && x !== ''
        && y !== null && y !== ''
    ) {
        resetPosition = false;
        modal.css('width', width);
        modal.css('height', height);
        moveElement(modal, x, y);

        // Just in case,
        // reset the size and position if the stored location puts the chat box offscreen
        // (this is possible if the window size has changed since the last time)
        if (modal.is(':offscreen')) {
            resetPosition = true;
        }
    }

    if (resetPosition) {
        modal.css('width', '20%');
        modal.css('height', '50%');
        modal.css('webkitTransform', 'translate(0px, 0px)');
        modal.css('transform', 'translate(0px, 0px)');
        modal.attr('data-x', 0);
        modal.attr('data-y', 0);
    }

    // Scroll to the bottom of the chat
    const chat = document.getElementById('game-chat-text');
    chat.scrollTop = chat.scrollHeight;

    $('#game-chat-input').focus();
};
exports.show = show;

const hide = () => {
    $('#game-chat-modal').fadeOut(globals.fadeTime);
};
exports.hide = hide;

// Subroutine to move an element (using the "transform" CSS property)
function moveElement(element, x, y) {
    // Update the element's style
    const transform = `translate(${x}px, ${y}px)`;
    element.css('webkitTransform', transform);
    element.css('transform', transform);

    // Keep the dragged position in the "data-x" & "data-y" attributes
    element.attr('data-x', x);
    element.attr('data-y', y);
}
