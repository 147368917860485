module.exports={
    "No Variant": {
        "id": 0,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Six Suits": {
        "id": 1,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Four Suits": {
        "id": 15,
        "suits": ["Blue", "Green", "Yellow", "Red"]
    },
    "Three Suits": {
        "id": 18,
        "suits": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Black (6 Suits)": {
        "id": 2,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Black"]
    },
    "Black (5 Suits)": {
        "id": 21,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black"],
        "spacing": true
    },

    "Rainbow (6 Suits)": {
        "id": 3,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Rainbow"]
    },
    "Rainbow (5 Suits)": {
        "id": 16,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow"]
    },
    "Rainbow (4 Suits)": {
        "id": 17,
        "suits": ["Blue", "Green", "Yellow", "Rainbow"]
    },
    "Rainbow (3 Suits)": {
        "id": 19,
        "suits": ["Blue", "Green", "Rainbow"],
        "spacing": true
    },

    "Pink (6 Suits)": {
        "id": 106,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Pink"]
    },
    "Pink (5 Suits)": {
        "id": 107,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink"]
    },
    "Pink (4 Suits)": {
        "id": 108,
        "suits": ["Blue", "Green", "Yellow", "Pink"]
    },
    "Pink (3 Suits)": {
        "id": 109,
        "suits": ["Blue", "Green", "Pink"],
        "spacing": true
    },

    "White (6 Suits)": {
        "id": 13,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "White"]
    },
    "White (5 Suits)": {
        "id": 22,
        "suits": ["Blue", "Green", "Yellow", "Red", "White"]
    },
    "White (4 Suits)": {
        "id": 26,
        "suits": ["Blue", "Green", "Yellow", "White"]
    },
    "White (3 Suits)": {
        "id": 27,
        "suits": ["Blue", "Green", "White"],
        "spacing": true
    },

    "Brown (6 Suits)": {
        "id": 69,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Brown"]
    },
    "Brown (5 Suits)": {
        "id": 70,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown"]
    },
    "Brown (4 Suits)": {
        "id": 71,
        "suits": ["Blue", "Green", "Yellow", "Brown"]
    },
    "Brown (3 Suits)": {
        "id": 72,
        "suits": ["Blue", "Green", "Brown"],
        "spacing": true
    },

    "Omni (6 Suits)": {
        "id": 176,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Omni"]
    },
    "Omni (5 Suits)": {
        "id": 177,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni"]
    },
    "Omni (4 Suits)": {
        "id": 178,
        "suits": ["Blue", "Green", "Yellow", "Omni"]
    },
    "Omni (3 Suits)": {
        "id": 179,
        "suits": ["Blue", "Green", "Omni"],
        "spacing": true
    },

    "Null (6 Suits)": {
        "id": 180,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Null"]
    },
    "Null (5 Suits)": {
        "id": 181,
        "suits": ["Blue", "Green", "Yellow", "Red", "Null"]
    },
    "Null (4 Suits)": {
        "id": 182,
        "suits": ["Blue", "Green", "Yellow", "Null"]
    },
    "Null (3 Suits)": {
        "id": 183,
        "suits": ["Blue", "Green", "Null"],
        "spacing": true
    },

    "Black & Rainbow (6 Suits)": {
        "id": 65,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Rainbow"]
    },
    "Black & Rainbow (5 Suits)": {
        "id": 66,
        "suits": ["Blue", "Green", "Yellow", "Black", "Rainbow"],
        "spacing": true
    },

    "Black & Pink (6 Suits)": {
        "id": 118,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Pink"]
    },
    "Black & Pink (5 Suits)": {
        "id": 119,
        "suits": ["Blue", "Green", "Yellow", "Black", "Pink"],
        "spacing": true
    },

    "Black & White (6 Suits)": {
        "id": 63,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "White"]
    },
    "Black & White (5 Suits)": {
        "id": 64,
        "suits": ["Blue", "Green", "Yellow", "Black", "White"],
        "spacing": true
    },

    "Black & Brown (6 Suits)": {
        "id": 73,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Brown"]
    },
    "Black & Brown (5 Suits)": {
        "id": 74,
        "suits": ["Blue", "Green", "Yellow", "Black", "Brown"],
        "spacing": true
    },

    "Black & Omni (6 Suits)": {
        "id": 186,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Omni"]
    },
    "Black & Omni (5 Suits)": {
        "id": 187,
        "suits": ["Blue", "Green", "Yellow", "Black", "Omni"],
        "spacing": true
    },

    "Black & Null (6 Suits)": {
        "id": 188,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Null"]
    },
    "Black & Null (5 Suits)": {
        "id": 189,
        "suits": ["Blue", "Green", "Yellow", "Black", "Null"],
        "spacing": true
    },

    "Rainbow & Pink (6 Suits)": {
        "id": 120,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Pink"]
    },
    "Rainbow & Pink (5 Suits)": {
        "id": 121,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Pink"]
    },
    "Rainbow & Pink (4 Suits)": {
        "id": 122,
        "suits": ["Blue", "Green", "Rainbow", "Pink"]
    },
    "Rainbow & Pink (3 Suits)": {
        "id": 149,
        "suits": ["Blue", "Rainbow", "Pink"],
        "spacing": true
    },

    "Rainbow & White (6 Suits)": {
        "id": 6,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "White"]
    },
    "Rainbow & White (5 Suits)": {
        "id": 28,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "White"]
    },
    "Rainbow & White (4 Suits)": {
        "id": 29,
        "suits": ["Blue", "Green", "Rainbow", "White"]
    },
    "Rainbow & White (3 Suits)": {
        "id": 150,
        "suits": ["Blue", "Rainbow", "White"],
        "spacing": true
    },

    "Rainbow & Brown (6 Suits)": {
        "id": 75,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Brown"]
    },
    "Rainbow & Brown (5 Suits)": {
        "id": 76,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Brown"]
    },
    "Rainbow & Brown (4 Suits)": {
        "id": 77,
        "suits": ["Blue", "Green", "Rainbow", "Brown"]
    },
    "Rainbow & Brown (3 Suits)": {
        "id": 78,
        "suits": ["Blue", "Rainbow", "Brown"],
        "spacing": true
    },

    "Rainbow & Omni (6 Suits)": {
        "id": 190,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Omni"]
    },
    "Rainbow & Omni (5 Suits)": {
        "id": 191,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Omni"]
    },
    "Rainbow & Omni (4 Suits)": {
        "id": 192,
        "suits": ["Blue", "Green", "Rainbow", "Omni"]
    },
    "Rainbow & Omni (3 Suits)": {
        "id": 193,
        "suits": ["Blue", "Rainbow", "Omni"],
        "spacing": true
    },

    "Rainbow & Null (6 Suits)": {
        "id": 194,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Null"]
    },
    "Rainbow & Null (5 Suits)": {
        "id": 195,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Null"]
    },
    "Rainbow & Null (4 Suits)": {
        "id": 196,
        "suits": ["Blue", "Green", "Rainbow", "Null"]
    },
    "Rainbow & Null (3 Suits)": {
        "id": 197,
        "suits": ["Blue", "Rainbow", "Null"],
        "spacing": true
    },

    "Pink & White (6 Suits)": {
        "id": 123,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "White"]
    },
    "Pink & White (5 Suits)": {
        "id": 124,
        "suits": ["Blue", "Green", "Yellow", "Pink", "White"]
    },
    "Pink & White (4 Suits)": {
        "id": 125,
        "suits": ["Blue", "Green", "Pink", "White"]
    },
    "Pink & White (3 Suits)": {
        "id": 126,
        "suits": ["Blue", "Pink", "White"],
        "spacing": true
    },

    "Pink & Brown (6 Suits)": {
        "id": 127,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Brown"]
    },
    "Pink & Brown (5 Suits)": {
        "id": 128,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Brown"]
    },
    "Pink & Brown (4 Suits)": {
        "id": 129,
        "suits": ["Blue", "Green", "Pink", "Brown"]
    },
    "Pink & Brown (3 Suits)": {
        "id": 130,
        "suits": ["Blue", "Pink", "Brown"],
        "spacing": true
    },

    "Pink & Omni (6 Suits)": {
        "id": 198,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Omni"]
    },
    "Pink & Omni (5 Suits)": {
        "id": 199,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Omni"]
    },
    "Pink & Omni (4 Suits)": {
        "id": 200,
        "suits": ["Blue", "Green", "Pink", "Omni"]
    },
    "Pink & Omni (3 Suits)": {
        "id": 201,
        "suits": ["Blue", "Pink", "Omni"],
        "spacing": true
    },

    "Pink & Null (6 Suits)": {
        "id": 202,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Null"]
    },
    "Pink & Null (5 Suits)": {
        "id": 203,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Null"]
    },
    "Pink & Null (4 Suits)": {
        "id": 204,
        "suits": ["Blue", "Green", "Pink", "Null"]
    },
    "Pink & Null (3 Suits)": {
        "id": 205,
        "suits": ["Blue", "Pink", "Null"],
        "spacing": true
    },

    "White & Brown (6 Suits)": {
        "id": 79,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Brown"]
    },
    "White & Brown (5 Suits)": {
        "id": 80,
        "suits": ["Blue", "Green", "Yellow", "White", "Brown"]
    },
    "White & Brown (4 Suits)": {
        "id": 81,
        "suits": ["Blue", "Green", "White", "Brown"]
    },
    "White & Brown (3 Suits)": {
        "id": 82,
        "suits": ["Blue", "White", "Brown"],
        "spacing": true
    },

    "White & Omni (6 Suits)": {
        "id": 206,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Omni"]
    },
    "White & Omni (5 Suits)": {
        "id": 207,
        "suits": ["Blue", "Green", "Yellow", "White", "Omni"]
    },
    "White & Omni (4 Suits)": {
        "id": 208,
        "suits": ["Blue", "Green", "White", "Omni"]
    },
    "White & Omni (3 Suits)": {
        "id": 209,
        "suits": ["Blue", "White", "Omni"],
        "spacing": true
    },

    "White & Null (6 Suits)": {
        "id": 210,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Null"]
    },
    "White & Null (5 Suits)": {
        "id": 211,
        "suits": ["Blue", "Green", "Yellow", "White", "Null"]
    },
    "White & Null (4 Suits)": {
        "id": 212,
        "suits": ["Blue", "Green", "White", "Null"]
    },
    "White & Null (3 Suits)": {
        "id": 213,
        "suits": ["Blue", "White", "Null"],
        "spacing": true
    },

    "Brown & Omni (6 Suits)": {
        "id": 214,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown", "Omni"]
    },
    "Brown & Omni (5 Suits)": {
        "id": 215,
        "suits": ["Blue", "Green", "Yellow", "Brown", "Omni"]
    },
    "Brown & Omni (4 Suits)": {
        "id": 216,
        "suits": ["Blue", "Green", "Brown", "Omni"]
    },
    "Brown & Omni (3 Suits)": {
        "id": 217,
        "suits": ["Blue", "Brown", "Omni"],
        "spacing": true
    },

    "Brown & Null (6 Suits)": {
        "id": 218,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown", "Null"]
    },
    "Brown & Null (5 Suits)": {
        "id": 219,
        "suits": ["Blue", "Green", "Yellow", "Brown", "Null"]
    },
    "Brown & Null (4 Suits)": {
        "id": 220,
        "suits": ["Blue", "Green", "Brown", "Null"]
    },
    "Brown & Null (3 Suits)": {
        "id": 221,
        "suits": ["Blue", "Brown", "Null"],
        "spacing": true
    },

    "Omni & Null (6 Suits)": {
        "id": 222,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni", "Null"]
    },
    "Omni & Null (5 Suits)": {
        "id": 223,
        "suits": ["Blue", "Green", "Yellow", "Omni", "Null"]
    },
    "Omni & Null (4 Suits)": {
        "id": 224,
        "suits": ["Blue", "Green", "Omni", "Null"]
    },
    "Omni & Null (3 Suits)": {
        "id": 225,
        "suits": ["Blue", "Omni", "Null"],
        "spacing": true
    },

    "Dark Rainbow (6 Suits)": {
        "id": 11,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Dark Rainbow"]
    },
    "Dark Rainbow (5 Suits)": {
        "id": 23,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Rainbow"]
    },
    "Black & Dark Rainbow (6 Suits)": {
        "id": 12,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Dark Rainbow"]
    },
    "Pink & Dark Rainbow (6 Suits)": {
        "id": 131,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Dark Rainbow"]
    },
    "Pink & Dark Rainbow (5 Suits)": {
        "id": 132,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Dark Rainbow"]
    },
    "White & Dark Rainbow (6 Suits)": {
        "id": 83,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Dark Rainbow"]
    },
    "White & Dark Rainbow (5 Suits)": {
        "id": 84,
        "suits": ["Blue", "Green", "Yellow", "White", "Dark Rainbow"]
    },
    "Brown & Dark Rainbow (6 Suits)": {
        "id": 85,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown", "Dark Rainbow"]
    },
    "Brown & Dark Rainbow (5 Suits)": {
        "id": 86,
        "suits": ["Blue", "Green", "Yellow", "Brown", "Dark Rainbow"]
    },
    "Omni & Dark Rainbow (6 Suits)": {
        "id": 226,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni", "Dark Rainbow"]
    },
    "Omni & Dark Rainbow (5 Suits)": {
        "id": 227,
        "suits": ["Blue", "Green", "Yellow", "Omni", "Dark Rainbow"]
    },
    "Null & Dark Rainbow (6 Suits)": {
        "id": 228,
        "suits": ["Blue", "Green", "Yellow", "Red", "Null", "Dark Rainbow"]
    },
    "Null & Dark Rainbow (5 Suits)": {
        "id": 229,
        "suits": ["Blue", "Green", "Yellow", "Null", "Dark Rainbow"],
        "spacing": true
    },

    "Dark Pink (6 Suits)": {
        "id": 137,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Dark Pink"]
    },
    "Dark Pink (5 Suits)": {
        "id": 138,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Pink"]
    },
    "Black & Dark Pink (6 Suits)": {
        "id": 139,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Dark Pink"]
    },
    "Rainbow & Dark Pink (6 Suits)": {
        "id": 140,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Dark Pink"]
    },
    "Rainbow & Dark Pink (5 Suits)": {
        "id": 141,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Dark Pink"]
    },
    "White & Dark Pink (6 Suits)": {
        "id": 142,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Dark Pink"]
    },
    "White & Dark Pink (5 Suits)": {
        "id": 143,
        "suits": ["Blue", "Green", "Yellow", "White", "Dark Pink"]
    },
    "Brown & Dark Pink (6 Suits)": {
        "id": 144,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown", "Dark Pink"]
    },
    "Brown & Dark Pink (5 Suits)": {
        "id": 145,
        "suits": ["Blue", "Green", "Yellow", "Brown", "Dark Pink"]
    },
    "Omni & Dark Pink (6 Suits)": {
        "id": 230,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni", "Dark Pink"]
    },
    "Omni & Dark Pink (5 Suits)": {
        "id": 231,
        "suits": ["Blue", "Green", "Yellow", "Omni", "Dark Pink"]
    },
    "Null & Dark Pink (6 Suits)": {
        "id": 232,
        "suits": ["Blue", "Green", "Yellow", "Red", "Null", "Dark Pink"]
    },
    "Null & Dark Pink (5 Suits)": {
        "id": 233,
        "suits": ["Blue", "Green", "Yellow", "Null", "Dark Pink"],
        "spacing": true
    },

    "Gray (6 Suits)": {
        "id": 59,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Gray"]
    },
    "Gray (5 Suits)": {
        "id": 58,
        "suits": ["Blue", "Green", "Yellow", "Red", "Gray"]
    },
    "Black & Gray (6 Suits)": {
        "id": 60,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Gray"]
    },
    "Rainbow & Gray (6 Suits)": {
        "id": 67,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Gray"]
    },
    "Rainbow & Gray (5 Suits)": {
        "id": 68,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Gray"]
    },
    "Pink & Gray (6 Suits)": {
        "id": 133,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Gray"]
    },
    "Pink & Gray (5 Suits)": {
        "id": 134,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Gray"]
    },
    "Brown & Gray (6 Suits)": {
        "id": 87,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown", "Gray"]
    },
    "Brown & Gray (5 Suits)": {
        "id": 88,
        "suits": ["Blue", "Green", "Yellow", "Brown", "Gray"]
    },
    "Omni & Gray (6 Suits)": {
        "id": 234,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni", "Gray"]
    },
    "Omni & Gray (5 Suits)": {
        "id": 235,
        "suits": ["Blue", "Green", "Yellow", "Omni", "Gray"]
    },
    "Null & Gray (6 Suits)": {
        "id": 236,
        "suits": ["Blue", "Green", "Yellow", "Red", "Null", "Gray"]
    },
    "Null & Gray (5 Suits)": {
        "id": 237,
        "suits": ["Blue", "Green", "Yellow", "Null", "Gray"],
        "spacing": true
    },

    "Chocolate (6 Suits)": {
        "id": 89,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Chocolate"]
    },
    "Chocolate (5 Suits)": {
        "id": 90,
        "suits": ["Blue", "Green", "Yellow", "Red", "Chocolate"]
    },
    "Black & Chocolate (6 Suits)": {
        "id": 91,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Chocolate"]
    },
    "Rainbow & Chocolate (6 Suits)": {
        "id": 92,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Chocolate"]
    },
    "Rainbow & Chocolate (5 Suits)": {
        "id": 93,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Chocolate"]
    },
    "Pink & Chocolate (6 Suits)": {
        "id": 135,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Chocolate"]
    },
    "Pink & Chocolate (5 Suits)": {
        "id": 136,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Chocolate"]
    },
    "White & Chocolate (6 Suits)": {
        "id": 94,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Chocolate"]
    },
    "White & Chocolate (5 Suits)": {
        "id": 95,
        "suits": ["Blue", "Green", "Yellow", "White", "Chocolate"]
    },
    "Omni & Chocolate (6 Suits)": {
        "id": 238,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni", "Chocolate"]
    },
    "Omni & Chocolate (5 Suits)": {
        "id": 239,
        "suits": ["Blue", "Green", "Yellow", "Omni", "Chocolate"]
    },
    "Null & Chocolate (6 Suits)": {
        "id": 240,
        "suits": ["Blue", "Green", "Yellow", "Red", "Null", "Chocolate"]
    },
    "Null & Chocolate (5 Suits)": {
        "id": 241,
        "suits": ["Blue", "Green", "Yellow", "Null", "Chocolate"],
        "spacing": true
    },

    "Dark Omni (6 Suits)": {
        "id": 242,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Dark Omni"]
    },
    "Dark Omni (5 Suits)": {
        "id": 243,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Omni"]
    },
    "Black & Dark Omni (6 Suits)": {
        "id": 244,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Dark Omni"]
    },
    "Rainbow & Dark Omni (6 Suits)": {
        "id": 245,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Dark Omni"]
    },
    "Rainbow & Dark Omni (5 Suits)": {
        "id": 246,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Dark Omni"]
    },
    "Pink & Dark Omni (6 Suits)": {
        "id": 247,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Dark Omni"]
    },
    "Pink & Dark Omni (5 Suits)": {
        "id": 248,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Dark Omni"]
    },
    "White & Dark Omni (6 Suits)": {
        "id": 249,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Dark Omni"]
    },
    "White & Dark Omni (5 Suits)": {
        "id": 250,
        "suits": ["Blue", "Green", "Yellow", "White", "Dark Omni"]
    },
    "Brown & Dark Omni (6 Suits)": {
        "id": 251,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown", "Dark Omni"]
    },
    "Brown & Dark Omni (5 Suits)": {
        "id": 252,
        "suits": ["Blue", "Green", "Yellow", "Brown", "Dark Omni"]
    },
    "Null & Dark Omni (6 Suits)": {
        "id": 253,
        "suits": ["Blue", "Green", "Yellow", "Red", "Null", "Dark Omni"]
    },
    "Null & Dark Omni (5 Suits)": {
        "id": 254,
        "suits": ["Blue", "Green", "Yellow", "Null", "Dark Omni"],
        "spacing": true
    },

    "Dark Null (6 Suits)": {
        "id": 255,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Dark Null"]
    },
    "Dark Null (5 Suits)": {
        "id": 256,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Null"]
    },
    "Black & Dark Null (6 Suits)": {
        "id": 257,
        "suits": ["Blue", "Green", "Yellow", "Red", "Black", "Dark Null"]
    },
    "Rainbow & Dark Null (6 Suits)": {
        "id": 258,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow", "Dark Null"]
    },
    "Rainbow & Dark Null (5 Suits)": {
        "id": 259,
        "suits": ["Blue", "Green", "Yellow", "Rainbow", "Dark Null"]
    },
    "Pink & Dark Null (6 Suits)": {
        "id": 260,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink", "Dark Null"]
    },
    "Pink & Dark Null (5 Suits)": {
        "id": 261,
        "suits": ["Blue", "Green", "Yellow", "Pink", "Dark Null"]
    },
    "White & Dark Null (6 Suits)": {
        "id": 262,
        "suits": ["Blue", "Green", "Yellow", "Red", "White", "Dark Null"]
    },
    "White & Dark Null (5 Suits)": {
        "id": 263,
        "suits": ["Blue", "Green", "Yellow", "White", "Dark Null"]
    },
    "Brown & Dark Null (6 Suits)": {
        "id": 264,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown", "Dark Null"]
    },
    "Brown & Dark Null (5 Suits)": {
        "id": 265,
        "suits": ["Blue", "Green", "Yellow", "Brown", "Dark Null"]
    },
    "Omni & Dark Null (6 Suits)": {
        "id": 266,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni", "Dark Null"]
    },
    "Omni & Dark Null (5 Suits)": {
        "id": 267,
        "suits": ["Blue", "Green", "Yellow", "Omni", "Dark Null"],
        "spacing": true
    },

    "Dark Rainbow & Dark Pink (6 Suits)": {
        "id": 146,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Rainbow", "Dark Pink"]
    },
    "Dark Rainbow & Gray (6 Suits)": {
        "id": 62,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Rainbow", "Gray"]
    },
    "Dark Rainbow & Chocolate (6 Suits)": {
        "id": 96,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Rainbow", "Chocolate"]
    },
    "Dark Rainbow & Dark Omni (6 Suits)": {
        "id": 268,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Rainbow", "Dark Omni"]
    },
    "Dark Rainbow & Dark Null (6 Suits)": {
        "id": 269,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Rainbow", "Dark Null"]
    },
    "Dark Pink & Gray (6 Suits)": {
        "id": 147,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Pink", "Gray"]
    },
    "Dark Pink & Chocolate (6 Suits)": {
        "id": 148,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Pink", "Chocolate"]
    },
    "Dark Pink & Dark Omni (6 Suits)": {
        "id": 270,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Pink", "Dark Omni"]
    },
    "Dark Pink & Dark Null (6 Suits)": {
        "id": 271,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Pink", "Dark Null"]
    },
    "Gray & Chocolate (6 Suits)": {
        "id": 97,
        "suits": ["Blue", "Green", "Yellow", "Red", "Gray", "Chocolate"]
    },
    "Gray & Dark Omni (6 Suits)": {
        "id": 272,
        "suits": ["Blue", "Green", "Yellow", "Red", "Gray", "Dark Omni"]
    },
    "Gray & Dark Null (6 Suits)": {
        "id": 273,
        "suits": ["Blue", "Green", "Yellow", "Red", "Gray", "Dark Null"]
    },
    "Chocolate & Dark Omni (6 Suits)": {
        "id": 274,
        "suits": ["Blue", "Green", "Yellow", "Red", "Chocolate", "Dark Omni"]
    },
    "Chocolate & Dark Null (6 Suits)": {
        "id": 275,
        "suits": ["Blue", "Green", "Yellow", "Red", "Chocolate", "Dark Null"]
    },
    "Dark Omni & Dark Null (6 Suits)": {
        "id": 276,
        "suits": ["Blue", "Green", "Yellow", "Red", "Dark Omni", "Dark Null"],
        "spacing": true
    },

    "Muddy Rainbow (6 Suits)": {
        "id": 160,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Muddy Rainbow"]
    },
    "Muddy Rainbow (5 Suits)": {
        "id": 161,
        "suits": ["Blue", "Green", "Yellow", "Red", "Muddy Rainbow"]
    },
    "Muddy Rainbow (4 Suits)": {
        "id": 162,
        "suits": ["Blue", "Green", "Yellow", "Muddy Rainbow"]
    },
    "Muddy Rainbow (3 Suits)": {
        "id": 163,
        "suits": ["Blue", "Green", "Muddy Rainbow"],
        "spacing": true
    },

    "Light Pink (6 Suits)": {
        "id": 164,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Light Pink"]
    },
    "Light Pink (5 Suits)": {
        "id": 165,
        "suits": ["Blue", "Green", "Yellow", "Red", "Light Pink"]
    },
    "Light Pink (4 Suits)": {
        "id": 166,
        "suits": ["Blue", "Green", "Yellow", "Light Pink"]
    },
    "Light Pink (3 Suits)": {
        "id": 167,
        "suits": ["Blue", "Green", "Light Pink"],
        "spacing": true
    },

    "Color Blind (6 Suits)": {
        "id": 10,
        "suits": ["Blue CB", "Green CB", "Yellow CB", "Red CB", "Purple CB", "Teal CB"],
        "clueColors": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Color Blind (5 Suits)": {
        "id": 33,
        "suits": ["Blue CB", "Green CB", "Yellow CB", "Red CB", "Purple CB"],
        "clueColors": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Color Blind (4 Suits)": {
        "id": 34,
        "suits": ["Blue CB", "Green CB", "Yellow CB", "Red CB"],
        "clueColors": ["Blue", "Green", "Yellow", "Red"]
    },
    "Color Blind (3 Suits)": {
        "id": 35,
        "suits": ["Blue CB", "Green CB", "Yellow CB"],
        "clueColors": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Number Blind (6 Suits)": {
        "id": 102,
        "suits": ["Blue NB", "Green NB", "Yellow NB", "Red NB", "Purple NB", "Teal NB"]
    },
    "Number Blind (5 Suits)": {
        "id": 103,
        "suits": ["Blue NB", "Green NB", "Yellow NB", "Red NB", "Purple NB"]
    },
    "Number Blind (4 Suits)": {
        "id": 104,
        "suits": ["Blue NB", "Green NB", "Yellow NB", "Red NB"]
    },
    "Number Blind (3 Suits)": {
        "id": 105,
        "suits": ["Blue NB", "Green NB", "Yellow NB"],
        "spacing": true
    },

    "Totally Blind (6 Suits)": {
        "id": 151,
        "suits": ["Blue TB", "Green TB", "Yellow TB", "Red TB", "Purple TB", "Teal TB"],
        "clueColors": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Totally Blind (5 Suits)": {
        "id": 152,
        "suits": ["Blue TB", "Green TB", "Yellow TB", "Red TB", "Purple TB"],
        "clueColors": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Totally Blind (4 Suits)": {
        "id": 153,
        "suits": ["Blue TB", "Green TB", "Yellow TB", "Red TB"],
        "clueColors": ["Blue", "Green", "Yellow", "Red"]
    },
    "Totally Blind (3 Suits)": {
        "id": 154,
        "suits": ["Blue TB", "Green TB", "Yellow TB"],
        "clueColors": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Color Mute (6 Suits)": {
        "id": 110,
        "suits": ["Blue CB", "Green CB", "Yellow CB", "Red CB", "Purple CB", "Teal CB"]
    },
    "Color Mute (5 Suits)": {
        "id": 111,
        "suits": ["Blue CB", "Green CB", "Yellow CB", "Red CB", "Purple CB"]
    },
    "Color Mute (4 Suits)": {
        "id": 112,
        "suits": ["Blue CB", "Green CB", "Yellow CB", "Red CB"]
    },
    "Color Mute (3 Suits)": {
        "id": 113,
        "suits": ["Blue CB", "Green CB", "Yellow CB"],
        "spacing": true
    },

    "Number Mute (6 Suits)": {
        "id": 114,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Number Mute (5 Suits)": {
        "id": 115,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Number Mute (4 Suits)": {
        "id": 116,
        "suits": ["Blue", "Green", "Yellow", "Red"]
    },
    "Number Mute (3 Suits)": {
        "id": 117,
        "suits": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Ambiguous (6 Suits)": {
        "id": 8,
        "suits": ["Sky", "Navy", "Lime", "Forest", "Tomato", "Mahogany"],
        "showSuitNames": true
    },
    "Ambiguous (4 Suits)": {
        "id": 30,
        "suits": ["Sky", "Navy", "Tomato", "Mahogany"],
        "showSuitNames": true
    },
    "Ambiguous & White (5 Suits)": {
        "id": 31,
        "suits": ["Sky", "Navy", "Tomato", "Mahogany", "White"],
        "showSuitNames": true
    },
    "Ambiguous & Rainbow (5 Suits)": {
        "id": 32,
        "suits": ["Sky", "Navy", "Tomato", "Mahogany", "Rainbow"],
        "showSuitNames": true
    },
    "Very Ambiguous (6 Suits)": {
        "id": 9,
        "suits": ["Sky VA", "Berry VA", "Navy VA", "Tomato VA", "Ruby VA", "Mahogany VA"],
        "showSuitNames": true,
        "spacing": true
    },

    "Dual-Color (6 Suits)": {
        "id": 4,
        "suits": ["Green D", "Purple D", "Navy D", "Orange D", "Tan D", "Mahogany D"],
        "showSuitNames": true
    },
    "Dual-Color (5 Suits)": {
        "id": 36,
        "suits": ["Teal D", "Lime D", "Orange D2", "Cardinal D", "Indigo D"],
        "showSuitNames": true
    },
    "Dual-Color (3 Suits)": {
        "id": 37,
        "suits": ["Green D", "Purple D", "Orange D2"],
        "showSuitNames": true
    },
    "Dual-Color & Rainbow (6 Suits)": {
        "id": 5,
        "suits": ["Teal D", "Lime D", "Orange D2", "Cardinal D", "Indigo D", "Rainbow"],
        "showSuitNames": true
    },
    "Dual-Color & Rainbow (4 Suits)": {
        "id": 38,
        "suits": ["Green D", "Purple D", "Orange D2", "Rainbow"],
        "showSuitNames": true,
        "spacing": true
    },

    "Multi-Fives (6 Suits)": {
        "id": 45,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Multi-Fives (5 Suits)": {
        "id": 46,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Multi-Fives (4 Suits)": {
        "id": 49,
        "suits": ["Blue", "Green", "Yellow", "Red"]
    },
    "Multi-Fives (3 Suits)": {
        "id": 50,
        "suits": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Multi-Fives & Rainbow (6 Suits)": {
        "id": 39,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Rainbow"]
    },
    "Multi-Fives & Rainbow (5 Suits)": {
        "id": 14,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow"]
    },
    "Multi-Fives & Rainbow (4 Suits)": {
        "id": 47,
        "suits": ["Blue", "Green", "Yellow", "Rainbow"]
    },
    "Multi-Fives & Rainbow (3 Suits)": {
        "id": 48,
        "suits": ["Blue", "Green", "Rainbow"],
        "spacing": true
    },

    "Prism-Ones (6 Suits)": {
        "id": 168,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Prism-Ones (5 Suits)": {
        "id": 169,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Prism-Ones (4 Suits)": {
        "id": 170,
        "suits": ["Blue", "Green", "Yellow", "Red"]
    },
    "Prism-Ones (3 Suits)": {
        "id": 171,
        "suits": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Prism-Ones & Pink (6 Suits)": {
        "id": 172,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Pink"]
    },
    "Prism-Ones & Pink (5 Suits)": {
        "id": 173,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink"]
    },
    "Prism-Ones & Pink (4 Suits)": {
        "id": 174,
        "suits": ["Blue", "Green", "Yellow", "Pink"]
    },
    "Prism-Ones & Pink (3 Suits)": {
        "id": 175,
        "suits": ["Blue", "Green", "Pink"],
        "spacing": true
    },

    "Clue Starved (6 Suits)": {
        "id": 51,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Clue Starved (5 Suits)": {
        "id": 52,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Clue Starved (4 Suits)": {
        "id": 53,
        "suits": ["Blue", "Green", "Yellow", "Red"],
        "spacing": true
    },

    "Up or Down (6 Suits)": {
        "id": 40,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"],
        "showSuitNames": true
    },
    "Up or Down (5 Suits)": {
        "id": 24,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"],
        "showSuitNames": true
    },
    "Up or Down & Rainbow (6 Suits)": {
        "id": 25,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Rainbow"],
        "showSuitNames": true
    },
    "Up or Down & Rainbow (5 Suits)": {
        "id": 43,
        "suits": ["Blue", "Green", "Yellow", "Red", "Rainbow"],
        "showSuitNames": true
    },
    "Up or Down & Pink (6 Suits)": {
        "id": 155,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Pink"],
        "showSuitNames": true
    },
    "Up or Down & Pink (5 Suits)": {
        "id": 156,
        "suits": ["Blue", "Green", "Yellow", "Red", "Pink"],
        "showSuitNames": true
    },
    "Up or Down & White (6 Suits)": {
        "id": 41,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "White"],
        "showSuitNames": true
    },
    "Up or Down & White (5 Suits)": {
        "id": 42,
        "suits": ["Blue", "Green", "Yellow", "Red", "White"],
        "showSuitNames": true
    },
    "Up or Down & Brown (6 Suits)": {
        "id": 157,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Brown"],
        "showSuitNames": true
    },
    "Up or Down & Brown (5 Suits)": {
        "id": 158,
        "suits": ["Blue", "Green", "Yellow", "Red", "Brown"],
        "showSuitNames": true
    },
    "Up or Down & Omni (6 Suits)": {
        "id": 277,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Omni"],
        "showSuitNames": true
    },
    "Up or Down & Omni (5 Suits)": {
        "id": 278,
        "suits": ["Blue", "Green", "Yellow", "Red", "Omni"],
        "showSuitNames": true
    },
    "Up or Down & Null (6 Suits)": {
        "id": 61,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Null"],
        "showSuitNames": true
    },
    "Up or Down & Null (5 Suits)": {
        "id": 44,
        "suits": ["Blue", "Green", "Yellow", "Red", "Null"],
        "showSuitNames": true,
        "spacing": true
    },

    "Cow & Pig (6 Suits)": {
        "id": 98,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Cow & Pig (5 Suits)": {
        "id": 99,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Cow & Pig (4 Suits)": {
        "id": 100,
        "suits": ["Blue", "Green", "Yellow", "Red"]
    },
    "Cow & Pig (3 Suits)": {
        "id": 101,
        "suits": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Duck (6 Suits)": {
        "id": 54,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Duck (5 Suits)": {
        "id": 55,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Duck (4 Suits)": {
        "id": 56,
        "suits": ["Blue", "Green", "Yellow", "Red"]
    },
    "Duck (3 Suits)": {
        "id": 57,
        "suits": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Throw It in a Hole (6 Suits)": {
        "id": 279,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple", "Teal"]
    },
    "Throw It in a Hole (5 Suits)": {
        "id": 280,
        "suits": ["Blue", "Green", "Yellow", "Red", "Purple"]
    },
    "Throw It in a Hole (4 Suits)": {
        "id": 281,
        "suits": ["Blue", "Green", "Yellow", "Red"]
    },
    "Throw It in a Hole (3 Suits)": {
        "id": 282,
        "suits": ["Blue", "Green", "Yellow"],
        "spacing": true
    },

    "Special Mix (5 Suit)": {
        "id": 159,
        "suits": ["Black", "Rainbow", "Pink", "White", "Brown"]
    },
    "Special Mix (6 Suit)": {
        "id": 185,
        "suits": ["Black", "Rainbow", "Pink", "White", "Brown", "Null"]
    },
    "Ambiguous Mix": {
        "id": 20,
        "suits": ["Sky", "Navy", "Tomato", "Mahogany", "Black", "White"],
        "showSuitNames": true
    },
    "Dual-Color Mix": {
        "id": 7,
        "suits": ["Green D", "Purple D", "Orange D2", "Black", "Rainbow", "White"]
    },
    "Ambiguous & Dual-Color": {
        "id": 184,
        "suits": ["Lime AD", "Forest AD", "Orchid AD", "Violet AD", "Tangelo AD", "Peach AD"],
        "showSuitNames": true
    }
}
